<div class="container-fluid otp-selection-main d-flex flex-column">
    <div class="selection-ui-sub">
        <div class="card border-0">
            <div class="card-body px-0">
                <div class="otp-service-head-container">
                    <h5 class="otp-selection-heading kl-h6-type-1">How to send OTP ?</h5>
                </div>
                <div class="button-wrapper w-100 text-center mt-5">
                    <button type="submit" class="kl-btn-primary transparent-btn w-100" (click)="onSendToPhone()">
                        <span class="kl-h5-type-6">Send to phone number</span>
                    </button>
                </div>
                <div class="button-wrapper w-100 text-center mt-2 mb-3">
                    <button type="submit" class="kl-btn-primary w-100" (click)="onSendToEmail()">
                        <span class="kl-h5-type-7">Send to email ID</span>
                    </button>
                </div>
                <div
                    class="kl-text1-type-3 w-100 text-center cursor-pointer"
                >
                    <span 
                        class="d-flex justify-content-center align-items-center"
                        (click)="goBackToPreviousPage()"
                    >
                        <i class="go-back-icon bi bi-arrow-left"></i>
                        Go Back
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
