import { createAction } from '@ngrx/store';

import {
  AuthModuleStates,
  ClinicListForSelection,
  InitialTokenDetails,
  LoginResponseData,
  OtpSelectionDetails,
  OtpTarget,
  Patient,
  SelectedRoleDetails,
  verifyOtpDetails } from '../models/auth.model';
import {
    ClinicSelectAndUpdateMutationVariables,
  ForgotPasswordMutationVariables,
  GetClinicsByDesignerIdQueryVariables,
  GetFamilyMembersUnderFranchiseClinicQueryVariables,
  GetRolesBasedOnUserIdQuery,
  GetSiblingListQueryVariables,
  GetUserAndUserRoleMappingByEmailQuery,
  GetUserAndUserRoleMappingByEmailQueryVariables,
  GetUserDetailsQuery,
  GetUserDetailsQueryVariables,
  GetUserProfileQuery,
  GetUserProfileQueryVariables,
  OnLoginMutationVariables,
  OnSendOtpBothServiceMutationVariables,
  ResetPasswordWithNewMutationVariables,
  UpdateNewUserDetailsMutationVariables,
  VerifyOtpMutationVariables} from '@app/generated/graphql';
import { Roles } from '@app/core/util/common';
import { User } from '@app/shared/models/shared.model';

// login related -----------
export const login = createAction(
    AuthModuleStates.Login,
    (params: OnLoginMutationVariables) => ({ params })
);
export const storeInitialLoginResponse = createAction(
    AuthModuleStates.LoginOk,
    (initialLoginDetails: InitialTokenDetails) => ({ initialLoginDetails })
);
export const resetInitialLoginDetails = createAction(
    AuthModuleStates.LoginFailure,
    (initialLoginDetails: InitialTokenDetails) => ({ initialLoginDetails })
);
export const updateNewUserDetails = createAction(
    AuthModuleStates.updateNewUserDetails,
    (updateNewUserDetailsMutationVariables: UpdateNewUserDetailsMutationVariables) => ({updateNewUserDetailsMutationVariables})
);

// Reset password related ----------
export const resetPassword = createAction(
    AuthModuleStates.ResetPassword,
    (forgotPwdParams: ForgotPasswordMutationVariables) => ({ forgotPwdParams })
);
export const resetPasswordSuccess = createAction(
    AuthModuleStates.ResetPasswordSuccess,
    (isPasswordReset: boolean) => ({ isPasswordReset })
);
export const resetPasswordFailure = createAction(
    AuthModuleStates.ResetPasswordFailure,
    (isPasswordReset: boolean) => ({ isPasswordReset })
);

// Role Fetching related ----------
export const fetchAllRolesOfUser = createAction(
    AuthModuleStates.UserRolesFetch,
    (userId: string) => ({ userId })
);
export const userRoleFetchSuccess = createAction(
    AuthModuleStates.UserRolesFetchSuccess,
    (userRoles: GetRolesBasedOnUserIdQuery) => ({ userRoles })
);
export const userRoleFetchFailure = createAction(
    AuthModuleStates.UserRolesFetchFailure,
    (userRoles: string[] ) => ({ userRoles })
);
export const roleSelectionInitiate = createAction(
    AuthModuleStates.roleSelectionInitiate,
    (selectedRoleDetails: { selectedRole: string, role: SelectedRoleDetails},isAfterLogin?: boolean) => ({ selectedRoleDetails, isAfterLogin })
)

// Clinic selection related
export const clinicListFetchForSelection = createAction(
    AuthModuleStates.ClinicListFetchForSelection
)
export const clinicListFetchForSelectionSuccess = createAction(
    AuthModuleStates.ClinicListFetchForSelectionSuccess,
    (clinicList: ClinicListForSelection[]) => ({ clinicList })
)
export const saveLoginDataForLaterClinicSelection = createAction(
    AuthModuleStates.SaveLoginDataForLaterClinicSelection,
    (loginData: LoginResponseData) => ({ loginData })
)
export const onClinicSelectForDoctor = createAction(
    AuthModuleStates.OnClinicSelectForDoctor,
    (clinicData: ClinicSelectAndUpdateMutationVariables, clinicExtra: {clinicRoleId: string}) => ({ clinicData, clinicExtra })
)
export const onClinicSelectForDoctorSuccess = createAction(
    AuthModuleStates.OnClinicSelectForDoctorSuccess,
    (loginData: LoginResponseData) => ({ loginData })
)
export const selectClinicAfterLogin = createAction(
    AuthModuleStates.SelectClinicAfterLogin,
    (showClinicSelection: boolean) => ({ showClinicSelection })
);


// otp service related ------------
export const sendOtpToBothServices = createAction(
    AuthModuleStates.sendOtpToBothServices,
    (otpMethodAndUserId: OnSendOtpBothServiceMutationVariables) => ({otpMethodAndUserId})
)
export const otpServiceSelectionDetails = createAction(
    AuthModuleStates.sendOtpPreferences,
    (preferredOtpMethod: OtpSelectionDetails) => ({ preferredOtpMethod })
)
export const resendOtp = createAction(
    AuthModuleStates.resendOtpToUser,
    (resendOtpParams: OnSendOtpBothServiceMutationVariables) => ({resendOtpParams})
)
export const verifyOtp = createAction(
    AuthModuleStates.VerifyOtp,
    (payload: VerifyOtpMutationVariables) => ({ payload })
)
export const verifyOtpStatus = createAction(
    AuthModuleStates.VerifyOtpStatus,
    (verifyOtpResponse: verifyOtpDetails) => ({ verifyOtpResponse })
)

// Change password related ------------
export const changePassword = createAction(
    AuthModuleStates.ChangePassword,
    (changePasswordData: ResetPasswordWithNewMutationVariables) => ({ changePasswordData })
)

// Basic user details related ----------
export const getUserDetails = createAction(
  AuthModuleStates.GetUserDetails,
  (param: GetUserDetailsQueryVariables) => ({ param })
);

export const getUserDetailsSuccess = createAction(
  AuthModuleStates.GetUserDetailsSuccess,
  (data: GetUserDetailsQuery) => ({ data })
);

// forgot password related ------------
export const checkIfEmailIsValid = createAction(
    AuthModuleStates.ForgotPasswordEmailValidation,
    (param: OtpTarget) => ({ param })
);
export const forgotPwdEmailValidationSuccess = createAction(
    AuthModuleStates.ForgotPasswordEmailValidationSuccess,
    (forgotPasswordParams: InitialTokenDetails) => ({ forgotPasswordParams })
);

// clearing auth related data on successfull login ----------
export const clearAuthRelatedInitialData = createAction(
    AuthModuleStates.ClearAuthRelatedInitialData
);

export const clearAuthRelatedInitialDataSuccess = createAction(
    AuthModuleStates.ClearAuthRelatedInitialDataSuccess
);

// Validate Doctor email actions
export const validateDoctorEmail = createAction(
  AuthModuleStates.ValidateDoctorEmail,
  (emailPayload: GetUserAndUserRoleMappingByEmailQueryVariables) => ({ emailPayload })
);

export const validateDoctorEmailSuccess = createAction(
  AuthModuleStates.ValidateDoctorEmailSuccess,
  (doctorDetails: GetUserAndUserRoleMappingByEmailQuery) => ({ doctorDetails })
);

export const doctorEmailValidationFailed = createAction(
  AuthModuleStates.DoctorEmailValidationFailed,
  (doctorEmailFailedMsg: string) => ({ doctorEmailFailedMsg })
)

export const loginSuperAdminToTheRequestedDoctorProfile = createAction(
  AuthModuleStates.LoginSuperAdminToTheRequestedDoctorProfile,
  (loggingRoleName: Roles) => ({ loggingRoleName })
)


export const getUserProfileDetails = createAction(
    AuthModuleStates.getUserProfileDetails,
    (userProfileParams: GetUserProfileQueryVariables) => ({userProfileParams})
)

export const getUserProfileDetailsSuccess = createAction(
    AuthModuleStates.getUserProfileDetailsSuccess,
    (userProfileDetails: GetUserProfileQuery) => ({userProfileDetails})
)

export const getClinicsByDesignerId = createAction(
  AuthModuleStates.getClinicsByDesignerId,
  (params: GetClinicsByDesignerIdQueryVariables) => ({ params })
);

export const getClinicsByDesignerIdSuccess = createAction(
  AuthModuleStates.getClinicsByDesignerIdSuccess,
  (clinicsByDesigner: User[] | null) => ({ clinicsByDesigner })
);

export const getSiblingList = createAction(
  AuthModuleStates.getSiblingList,
  (params: GetSiblingListQueryVariables) => ({ params })
);

export const getSiblingListSuccess = createAction(
  AuthModuleStates.getSiblingListSuccess,
  (siblingList: Patient[]):{siblingList: Patient[]} => ({ siblingList })
);

export const saveUserIdOfLoggedInUser = createAction(
  AuthModuleStates.saveUserIdOfLoggedInUser,
  (userId: string) => ({ userId })
);

export const checkIfPatientIsUnderFranchiseClinic = createAction(
  AuthModuleStates.checkIfPatientIsUnderFranchiseClinic,
  (params: GetFamilyMembersUnderFranchiseClinicQueryVariables) => ({ params })
);

export const saveParentPatientId = createAction(
  AuthModuleStates.saveParentPatientId,
  (parentPatientId: string | null) => ({ parentPatientId })
);
