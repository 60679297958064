import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { takeWhile } from 'rxjs';

import { fetchAllRolesOfUser } from '@app/auth/actions/auth.action';
import { AuthService } from '@app/core/services/auth.service';
import { CredentialsService } from '@app/core/services/credentials.service';
import { Roles, Url } from '@app/core/util/common';
@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
    organizationName = 'KALCULUS';
    organizationLogo = 'assets/images/authentication/kalculus-logo.png';
    isAlive = true;

    constructor(private _router: Router, private _authService: AuthService, private _credentialService: CredentialsService, private _store: Store) {}

    ngOnInit(): void {
      this._authService.onLoginEventAsObs().pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
        const role = this._credentialService.credentials?.role || '';
        if(value && (role !== Roles.PATIENT && role !== Roles.CARE_TAKER)){
          const userId = this._credentialService.credentials?.userId || '';
          this._store.dispatch(fetchAllRolesOfUser(userId));
        }
      })
    }

    /**
     * On clicking logo goto sigin page
     * @param Nil
     * @returns void
     */
    onLogoClick = (): void => {
        this._router.navigateByUrl(Url.LOGIN);
    }

    ngOnDestroy(): void {
      this.isAlive = false;
    }
}
