import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { metaReducers } from '.';
import { AuthEffects } from './effects/auth.effect';
import { authReducer } from './reducers/auth.reducer';
import { SharedModule } from '@app/shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './container/auth/auth.component';
import { LoginComponent } from './container/login/login.component';
import { SendOtpComponent } from './container/send-otp/send-otp.component';
import { UserRoleComponent } from './container/user-role/user-role.component';
import { UserProfileComponent } from './container/user-profile/user-profile.component';
import { SelectClinicComponent } from './container/select-clinic/select-clinic.component';
import { RoleSelectionComponent } from './component/role-selection/role-selection.component';
import { ChangePasswordComponent } from './container/change-password/change-password.component';
import { ResetPasswordComponent } from './container/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './container/forgot-password/forgot-password.component';
import { ClinicSelectionComponent } from './component/clinic-selection/clinic-selection.component';
import { TermsAndConditionsComponent } from './container/terms-and-conditions/terms-and-conditions.component';
import { OtpServiceSelectionComponent } from './container/otp-service-selection/otp-service-selection.component';
import { SiblingSelectionComponent } from './container/sibling-selection/sibling-selection.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RoleSelectionComponent,
    AuthComponent,
    SendOtpComponent,
    ChangePasswordComponent,
    OtpServiceSelectionComponent,
    ClinicSelectionComponent,
    UserRoleComponent,
    SelectClinicComponent,
    UserProfileComponent,
    TermsAndConditionsComponent,
    SiblingSelectionComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature('authentication', authReducer, { metaReducers }),
  ],
  exports: [ChangePasswordComponent,UserRoleComponent,SelectClinicComponent, UserProfileComponent]
})
export class AuthModule {}
