<form class="role-selection-form mt-4" [formGroup]="roleSelectionForm" (ngSubmit)="onSubmit()">
    <div class="role-selection-container" id="select-role-div">
        <div class="select-role-dropdown">
            <select class="form-select kl-input-text" formControlName="userRole" (change)="onChangeRole($event)">
                <option value="" disabled selected hidden>Select a role</option>
                <option *ngFor="let roleItem of (availableRoles$ | async)?.user_role_mapping" [value]="roleItem?.id">
                    {{
                        getActualRoleName(roleItem?.role?.name) === 'Doctor'
                            ? 'Practitioner'
                            : getActualRoleName(roleItem?.role?.name) === 'Regular Clinic'
                            ? 'Partner'
                            : getActualRoleName(roleItem?.role?.name) === 'Franchise Clinic'
                            ? 'Gold Partner'
                            : getActualRoleName(roleItem?.role?.name)
                    }}
                </option>
            </select>
        </div>
        <div class="error-message-container-common">
            <p class="error-text" *ngIf="(isDirty('userRole') || isTouched('userRole')) && isRequired('userRole')">
                Please select a role
            </p>
        </div>
    </div>
    <div class="doctors-role-access-auth-container mt-4" *ngIf="isSuperAdmin || isDesigner">
        <!-- Dashboard radio button start -->
        <p class="kl-text2-type-1 mb-2">Please select any option</p>
        <div class="d-flex mt-3">
            <label for="admin-dashboard" class="kl-check-default kl-radio-container mb-4 padding-radio-button"
                >{{isSuperAdmin ? 'Admin’s Dashboard' : 'Dashboard'}}
                <input
                    type="radio"
                    [value]="'admin-dashboard'"
                    id="admin-dashboard"
                    formControlName="dashboard"
                    name="dashboard"
                    [checked]="true"
                    (change)="onDashboardSelection('admin-dashboard')"
                />
                <span></span>
            </label>
            <label for="doctors-dashboard" class="kl-check-default kl-radio-container"
                >Practitioner’s Dashboard
                <input
                    type="radio"
                    id="doctors-dashboard"
                    [value]="'doctors-dashboard'"
                    formControlName="dashboard"
                    name="dashboard"
                    (change)="onDashboardSelection('doctors-dashboard')"
                />
                <span></span>
            </label>
        </div>
        <!-- Dashboard radio button end -->
        <!-- Doctor email id ui start -->
        <div [@dashboardTransition]="isDoctorsDashboardSelected() ? 'show' : 'hide'">
            <div class="mb-2 mt-3" id="user-name-or-email-div" *ngIf="isDoctorsDashboardSelected()">
                <label for="doctor-email" class="kl-text2-type-1 mb-2"> Enter email ID </label>
                <input
                    type="email"
                    formControlName="doctorEmail"
                    class="kl-input-text input-text-type-1"
                    id="doctor-email"
                    placeholder="Email address"
                    autocomplete="on"
                />
                <div class="error-message-container-common">
                    <p
                        class="error-text"
                        *ngIf="isRequired('doctorEmail') && (isDirty('doctorEmail') || isTouched('doctorEmail'))"
                    >
                        Email is required.
                    </p>
                    <p
                        class="error-text"
                        *ngIf="
                            !isValid('doctorEmail') &&
                            (isDirty('doctorEmail') || isTouched('doctorEmail')) &&
                            !isRequired('doctorEmail') &&
                            !(doctorEmailValidationFailedMsg$ | async)
                        "
                    >
                        Please enter a valid email.
                    </p>
                    <div *ngIf="{ data: (doctorDetails$ | async) } as result">
                        <p
                            class="feedback-message"
                            *ngIf="(isValidationDoctorEmail$ | async) === true"
                        >
                            Validating email user...
                        </p>
                        <p class="feedback-message" *ngIf="getDoctorName(result.data) as name">
                            Selected email ID belongs to <span>{{ name }}</span>
                        </p>
                        <p
                            class="error-text"
                            *ngIf="(doctorEmailValidationFailedMsg$ | async) && !getDoctorName(result.data)"
                        >
                            {{ doctorEmailValidationFailedMsg$ | async }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Doctor email id ui end -->
    </div>
    <div
        class="auth-form-btn-container mt-5 mb-sm-3 mb-md-3 mb-lg-3 d-flex justify-content-center"
        id="sign-in-btn-div"
    >
        <button type="submit" class="kl-btn-primary" id="sign-in-action-btn" [disabled]="!roleSelectionForm.valid">
            Sign In
        </button>
    </div>
</form>
