import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './container/login/login.component';
import { ResetPasswordComponent } from './container/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './container/forgot-password/forgot-password.component';
import { AuthComponent } from './container/auth/auth.component';
import { SendOtpComponent } from './container/send-otp/send-otp.component';
import { NoAuthGuard } from '@app/core/guards/no-auth.guard';
import { OtpServiceSelectionComponent } from './container/otp-service-selection/otp-service-selection.component';
import { ClinicSelectionComponent } from './component/clinic-selection/clinic-selection.component';
import { TermsAndConditionsComponent } from './container/terms-and-conditions/terms-and-conditions.component';
import { SiblingSelectionComponent } from './container/sibling-selection/sibling-selection.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        canActivate: [NoAuthGuard],
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'select-role',
                component: LoginComponent,
                data: { isRoleSelection: true }
            },
            {
                path: 'select-clinic',
                component: ClinicSelectionComponent,
            },
            {
                path: 'otp-service',
                component: OtpServiceSelectionComponent
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
            {
                path: 'otp',
                component: SendOtpComponent,
            },
            {
                path: 'terms-and-conditions',
                component: TermsAndConditionsComponent,
            },
            {
              path: 'select-family-member',
              component: SiblingSelectionComponent
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
