import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, takeWhile } from 'rxjs';

import { resetPassword } from '@app/auth/actions/auth.action';
import { PasswordStrengthValidator } from '@app/core/validators/password-strength-validator';
import { ForgotPasswordMutationVariables } from '@app/generated/graphql';
import { getEmailFromAuthData } from '@app/auth/selectors/auth.selector';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    showNewPassword = false;
    showConfirmPassword = false;
    resetPasswordForm!: FormGroup;
    userEmail = '';
    isAlive = true;
    forgotPasswordDetails = new Observable<string>();
    subscription$ = new Subscription();

    constructor(
        private _store: Store, 
    ) {
        this.resetPasswordFormInit();
        this.forgotPasswordDetails = this._store.select(getEmailFromAuthData);
        this.subscription$ = this.forgotPasswordDetails.pipe(
            takeWhile(() => this.isAlive))
            .subscribe((userData: string) => {
                this.userEmail = userData;
            });
    }

    ngOnInit(): void {}

    /**
     * This is to initialize form and controls for reset password
     * @param
     * @returns void
     */
    resetPasswordFormInit = (): void => {
        this.resetPasswordForm = new FormGroup(
            {
                newPassword: new FormControl<string | null>(null, 
                    [Validators.required, PasswordStrengthValidator, Validators.maxLength(128)]
                ),
                confirmPassword: new FormControl<string | null>(null, 
                    [Validators.required, PasswordStrengthValidator, Validators.maxLength(128)]
                ),
            },
            {
                validators: this.pwdMatchValidator,
            }
        );
    };

    /**
     * This is to check if both passwords match
     * @param control formcontrol of two fields
     * @returns null | Object with error key and boolean value
     */
    pwdMatchValidator = (control: AbstractControl): { mismatch: boolean } | null => {
        return control.value.newPassword === control.value.confirmPassword ? null : { mismatch: true };
    };

    /**
     * Hide/show password New Password
     * @param
     * @return void
     */
    toggleNewPassword = (): void => {
        this.showNewPassword = !this.showNewPassword;
    };

    /**
     * Hide/show password Confirm Password
     * @param
     * @return void
     */
    toggleConfirmPassword = (): void => {
        this.showConfirmPassword = !this.showConfirmPassword;
    };

    /**
     * Checks if the control is valid without any errors
     * @param name of the form control.
     */
    isValid = (name: string): boolean => {
        return this.resetPasswordForm.controls[name]?.valid;
    };

    /**
     * Checks if the control is touched.
     * @param name of the form control.
     */
    isTouched = (name: string): boolean => {
        return this.resetPasswordForm.controls[name]?.touched;
    };

    /**
     * Checks if the control is dirty.
     * @param name of the form control.
     */
    isDirty = (name: string): boolean => {
        return this.resetPasswordForm.controls[name]?.dirty;
    };
    /**
     * Checks if the control is required.
     * @param name of the form control.
     */
    isRequired = (name: string): boolean => {
        return this.resetPasswordForm.controls[name]?.errors?.['required'];
    };

    /**
     * Checks if the control is having strength error.
     * @param name of the form control.
     */
    isPasswordStrong = (name: string): boolean => {
        return this.resetPasswordForm.controls[name]?.hasError('passwordStrength');
    };

    /**
     * Checks whether there is max length error
     * @param name of the form control
     * @return boolean
     */
    isMaxLengthError = (name: string): boolean => {
        return this.resetPasswordForm.controls[name]?.errors?.['maxlength'];
    };

    /**
     * This is to reset password with new one
     * @param
     * @returns void
     */
    onResetPassword = (): void => {
        if (this.resetPasswordForm.valid) {
            const data: ForgotPasswordMutationVariables = {
                forgotPwdParams: {
                    password: this.resetPasswordForm.get('newPassword')?.value,
                    email: this.userEmail
                }
            };
            this._store.dispatch(resetPassword(data));
        }
    };

    ngOnDestroy(): void {
        this.isAlive = false;
        this.subscription$.unsubscribe();
    }
} 