<div class="container-fluid kalculus-eye-bg" id="signin-page-main">
    <div class="kalculus-eye-sub" id="signin-page-sub">
        <div class="row" id="logo-row-main">
            <div class="col-lg-12" id="logo-row-col">
                <div class="kalculus-logo-box p-3" id="logo-container-div">
                    <img
                        class="organization-logo-img cursor-pointer"
                        [src]="organizationLogo"
                        alt="Logo"
                        (click)="onLogoClick()"
                    />
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-sm-3" id="signin-row-main">
            <div
                class="login-column col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
                <div class="kalc-auth-container card bg-white">
                    <div class="card-body p-4">
                        <p class="kl-text2-type-1">
                            <span class="kl-h3-type-1">Welcome to </span>
                            <span class="kl-h3-type-2">{{ organizationName }}</span>
                        </p>
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
