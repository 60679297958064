import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, takeWhile } from 'rxjs';

import { ClinicListForSelection, SelectedRoleDetails } from '@app/auth/models/auth.model';
import { getListOfClinicsByDoctor, getUserIdOfDoctorForClinicList, selectClinicsByDesigner } from '@app/auth/selectors/auth.selector';
import { clinicListFetchForSelection, onClinicSelectForDoctor } from '@app/auth/actions/auth.action';
import { ClinicSelectAndUpdateMutationVariables } from '@app/generated/graphql';
import { User } from '@app/shared/models/shared.model';

@Component({
    selector: 'app-clinic-selection',
    templateUrl: './clinic-selection.component.html',
    styleUrls: ['./clinic-selection.component.scss'],
})
export class ClinicSelectionComponent {
    isAlive = true;
    userId = '';
    clinicsUnderDesigner: User[] = [];
    clinicSelectionForm: FormGroup = new FormGroup({});
    availableClinics$ = new Observable<ClinicListForSelection[]>();
    availableClinicList!: ClinicListForSelection[];
    clinic!: SelectedRoleDetails;

    constructor(private _fb: FormBuilder, private _store: Store) {
        this.createClinicSelectionForm();
    }

    ngOnInit(): void {
        this._store.dispatch(clinicListFetchForSelection());

        // get clinic list from store, save it
        this.availableClinics$ = this._store.select(getListOfClinicsByDoctor);
        this.availableClinics$.pipe(takeWhile(() => this.isAlive)).subscribe((clinicList: ClinicListForSelection[]) => {
            this.availableClinicList = clinicList;
        });

        // get user id from store
        this._store
            .select(getUserIdOfDoctorForClinicList)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((userId: string) => {
                this.userId = userId;
            });
        this.selectClinicsUnderDesigner();
    }

    /**
     * Create Clinic Selection form and control
     * @param
     * @return void
     */
    createClinicSelectionForm = (): void => {
        this.clinicSelectionForm = this._fb.group({
            clinic: ['', Validators.required],
        });
    };

    /**
     * Checks if the control is touched
     * @param name of the form control
     * @return boolean
     */
    isTouched = (name: string): boolean => {
        return this.clinicSelectionForm.controls[name].touched;
    };

    /**
     * Checks if the control is dirty.
     * @param name of the form control.
     */
    isDirty = (name: string): boolean => {
        return this.clinicSelectionForm.controls[name]?.dirty;
    };

    /**
     * Checks if the control is required.
     * @param name of the form control.
     */
    isRequired = (name: string): boolean => {
        return this.clinicSelectionForm.controls[name]?.errors?.['required'];
    };

    /**
     * Gets the form control
     * @returns Form Control
     */
    get clinicSelected(): any {
        return this.clinicSelectionForm.get('clinic');
    }

    /**
     * Fetches type of clinic from current selection by comparing clinic id
     * @returns clinic Type of clinic
     */
    get clinicTypeByClinicId(): string {
        const clinic = this.availableClinicList.find(
            (clinic: ClinicListForSelection) => clinic.clinicId === this.clinicSelected.value
        );
        return clinic ? clinic.clinicType : 'null';
    }

    /**
     * On clinic selection and click, if form valid clinic selection action dispatched
     * @param
     * @return void
     */
    onSubmit = (): void => {
        if (this.clinicSelectionForm.valid) {
            const clinicDetails: ClinicSelectAndUpdateMutationVariables = {
                clinic_id: this.clinicSelected.value,
                clinic_type: this.clinicTypeByClinicId,
                user_id: this.userId,
            };
            const selectedClinic = this.availableClinicList.find((clinic) => clinic?.clinicId === clinicDetails?.clinic_id);
            const clinicExtra = {clinicRoleId: selectedClinic?.clinicRoleId ?? ''};
            this._store.dispatch(onClinicSelectForDoctor(clinicDetails, clinicExtra));
        }
    };

    /**
     * select clinics under designer so that designer that designer can login to doctor profile only under their clinic.
     */
    selectClinicsUnderDesigner = (): void => {
        this._store
            .select(selectClinicsByDesigner)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((clinics: User[] | null) => {
                if (clinics) {
                    this.clinicsUnderDesigner = clinics;
                }
            });
    };

    /**
     * Disable clinics in dropdown which does not belong to the designer.
     * @param clinicId
     * @returns
     */
    checkToDisable = (clinicId: string): boolean => {
        if (this.clinicsUnderDesigner?.length <= 0) {
            return false;
        }
        const hasClinic = this.clinicsUnderDesigner?.some((clinic: User) => clinic?.id === clinicId);
        return !hasClinic;
    };

    ngOnDestroy() {
        this.isAlive = false;
    }
}
