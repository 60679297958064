<div class="clinic-selection-container">
    <h2
        class="sign-in kl-h6-type-1"
    >
        Partner Selection
    </h2>
    <form
        class="clinic-selection-form mt-4"
        [formGroup]="clinicSelectionForm"
        (ngSubmit)="onSubmit()"
    >
        <div class="clinic-selection-container" id="select-clinic-div">
            <div class="select-clinic-dropdown">
                <select
                    class="form-select kl-input-text"
                    formControlName="clinic"
                >
                    <option value="" disabled selected hidden>Select a partner</option>
                    <option
                        *ngFor="let clinic of availableClinics$ | async"
                        [disabled]="checkToDisable(clinic.clinicId)"
                        [value]="clinic?.clinicId"
                    >
                        {{ clinic.clinicName }}
                    </option>
                </select>
            </div>
            <div class="error-message-container-common">
                <p
                    class="error-text"
                    *ngIf="(isDirty('clinic') ||
                        isTouched('clinic')) &&
                        isRequired('clinic')"
                >
                    Please select a partner
                </p>
            </div>
        </div>
        <div
            class="auth-form-btn-container mt-5 mb-sm-3 mb-md-3 mb-lg-3 d-flex justify-content-center"
            id="sign-in-btn-div"
        >
            <button
                type="submit"
                class="kl-btn-primary"
                id="sign-in-action-btn"
                [disabled]="!clinicSelectionForm.valid"
            >
                Sign In
            </button>
        </div>
    </form>
</div>
