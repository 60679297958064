import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition, query } from '@angular/animations';

import { select, Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, Observable, Subscription, takeWhile } from 'rxjs';

import { Roles } from '@app/core/util/common';
import { SelectedRoleDetails } from '@app/auth/models/auth.model';
import { CredentialsService } from '@app/core/services/credentials.service';
import { GetRolesBasedOnUserIdQuery, GetUserAndUserRoleMappingByEmailQuery } from '@app/generated/graphql';
import {
    getClinicsByDesignerId,
    loginSuperAdminToTheRequestedDoctorProfile,
    roleSelectionInitiate,
    validateDoctorEmail,
} from '@app/auth/actions/auth.action';
import {
    availableUserRoles,
    selectDoctorDetailsByEmailForAuth,
    selectDoctorEmailValidationFailedMsg,
    selectIsValidatingDoctorEmail,
} from '@app/auth/selectors/auth.selector';

@Component({
    selector: 'app-role-selection',
    templateUrl: './role-selection.component.html',
    styleUrls: ['./role-selection.component.scss'],
    animations: [
        trigger('dashboardTransition', [
            state('show', style({ opacity: 1, height: '90px' })),
            state('hide', style({ opacity: 0, height: '0px' })),
            transition('show <=> hide', animate('300ms ease-in-out')),
        ]),
    ],
})
export class RoleSelectionComponent implements OnInit, OnDestroy {
    isAlive = true;
    isSuperAdmin = false;
    isDesigner = false;
    doctorId = '';
    loggedInUserId = '';
    roleSelectionForm: FormGroup = new FormGroup({});
    role!: SelectedRoleDetails;
    availableRoles!: GetRolesBasedOnUserIdQuery;
    isValidationDoctorEmail$!: Observable<boolean>;
    doctorEmailValidationFailedMsg$!: Observable<string | null>;
    availableRoles$ = new Observable<GetRolesBasedOnUserIdQuery>();
    doctorDetails$!: Observable<GetUserAndUserRoleMappingByEmailQuery | null>;
    availableRoleFetchSubscription$ = new Subscription();

    constructor(
        private _fb: FormBuilder,
        private _store: Store,
        private _credentialService: CredentialsService
    ) {
        this.createRoleSelectionForm();
        this.isValidationDoctorEmail$ = this._store.pipe(select(selectIsValidatingDoctorEmail)); // Check if server validation finished.
        this.doctorDetails$ = this._store.pipe(select(selectDoctorDetailsByEmailForAuth)); // Get doctor details.
        this.doctorEmailValidationFailedMsg$ = this._store.pipe(select(selectDoctorEmailValidationFailedMsg)); // Get doctor email validation failure message.
    }

    ngOnInit(): void {
        const authFlowDetails = JSON.parse(localStorage.getItem('authFlowDetails') || '');
        if (authFlowDetails?.initialAuthBasicDetails?.userId) {
            this.loggedInUserId = authFlowDetails?.initialAuthBasicDetails?.userId;
        }
        this.availableRoles$ = this._store.pipe(
            takeWhile(() => this.isAlive),
            select(availableUserRoles)
        );

        this.availableRoleFetchSubscription$ = this.availableRoles$.subscribe((roles: GetRolesBasedOnUserIdQuery) => {
            if (roles && roles?.user_role_mapping) {
                this.availableRoles = roles;
                this.doctorId = this.getDoctorId(roles);
                this.isSuperAdmin = this.checkIfSuperAdmin(this.availableRoles);
                this.isDesigner = this.checkIfDesigner(this.availableRoles);
            }
        });

        // Disable or enable email if required on user interaction.
        this.isValidationDoctorEmail$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((isProcessing: boolean) => {
                if (isProcessing) {
                    this.roleSelectionForm.get('doctorEmail')?.disable();
                } else {
                    this.roleSelectionForm.get('doctorEmail')?.enable();
                }
            });

        // Get doctor email validation error. Used to set error if the entered email is wrong or not belongs to a doctor.
        this.doctorEmailValidationFailedMsg$
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((errorMsg: string | null) => {
                if (errorMsg) {
                    this.roleSelectionForm.get('doctorEmail')?.setErrors({ serverError: errorMsg });
                } else {
                    this.roleSelectionForm.get('doctorEmail')?.setErrors(null);
                }
            });
    }

    /**
     * Get doctor role id.
     * @param roles user role array.
     * @returns
     */
    getDoctorId(roles: GetRolesBasedOnUserIdQuery): string {
        const doctorRole = roles?.user_role_mapping?.find((role) => role.role?.name === 'doctor');
        return doctorRole ? doctorRole?.id : null;
    }

    /**
     * Create Role Selection form and control
     * @param
     * @return void
     */
    createRoleSelectionForm = (): void => {
        this.roleSelectionForm = this._fb.group({
            userRole: ['', Validators.required],
            dashboard: ['admin-dashboard'],
            doctorEmail: [''],
        });
    };

    /**
     * Checks if the control is touched
     * @param name of the form control
     * @return boolean
     */
    isTouched = (name: string): boolean => {
        return this.roleSelectionForm?.controls[name]?.touched;
    };

    /**
     * Checks if the control is dirty.
     * @param name of the form control.
     */
    isDirty = (name: string): boolean => {
        return this.roleSelectionForm?.controls[name]?.dirty;
    };

    /**
     * Checks if the control is required.
     * @param name of the form control.
     */
    isRequired = (name: string): boolean => {
        return this.roleSelectionForm?.controls[name]?.errors?.['required'];
    };

    /**
     * Checks if the control is valid
     * @param name of the form control
     * @returns boolean
     */
    isValid = (name: string): boolean => {
        return this.roleSelectionForm?.controls[name]?.valid;
    };

    /**
     * Gets the form control
     * @returns Form Control
     */
    get roleSelected(): any {
        return this.roleSelectionForm?.get('userRole');
    }

    /**
     * Sets value to the control of selection
     * @param e Event passed on dropdown change
     * @return void
     */
    onChangeRole(e: any): void {
        this.roleSelected?.setValue(e.target.value, {
            onlySelf: true,
        });
        this.role = this.getRoleId(this.roleSelected?.value);
    }

    getRoleId = (selectedRoleMappingId: string): SelectedRoleDetails => {
        const selectedRole = this.availableRoles?.user_role_mapping?.find(
            (role: {
                __typename?: 'user_role_mapping';
                id: any;
                role?: { __typename?: 'role'; id: any; name: string } | null;
            }) => selectedRoleMappingId === role?.id
        );
        return selectedRole?.role as SelectedRoleDetails;
    };

    /**
     * Returns role names for showing in dropdown
     * @param roleValue string that is obtained from table
     * @return string to display in front end
     */
    getActualRoleName = (roleValue: string | undefined): string | void => {
        return this._credentialService.getActualRoleNameForDisplay(roleValue);
    };

    /**
     * On role selection and click, if form valid role selection action dispatched
     * @param
     * @return void
     */
    onSubmit = (): void => {
        if (this.roleSelectionForm?.valid) {
            const selectedRoleDetails: {
                selectedRole: string;
                role: SelectedRoleDetails;
            } = {
                selectedRole: this.roleSelected.value,
                role: this.role,
            };
            if (this.roleSelectionForm.get('dashboard')?.value === 'admin-dashboard') {
                this._store.dispatch(roleSelectionInitiate(selectedRoleDetails));
                return;
            }
            let loggingRoleName = Roles.EMPTY;
            if (this.isSuperAdmin) {
                loggingRoleName = Roles.SUPER_ADMIN;
            }
            if (this.isDesigner && !this.isSuperAdmin) {
                loggingRoleName = Roles.DESIGNER;
                this._store.dispatch(getClinicsByDesignerId({ designerId: this.loggedInUserId }));
            }
            this._store.dispatch(loginSuperAdminToTheRequestedDoctorProfile(loggingRoleName));
        }
    };

    /**
     *
     * @returns status of doctors dropdown select.
     */
    isDoctorsDashboardSelected(): boolean | null {
        const dashboardControl = this.roleSelectionForm.get('dashboard');
        return dashboardControl && dashboardControl.value === 'doctors-dashboard';
    }

    /**
     * If doctor dashboard is selected set role selected as doctor.
     * If admin dashboard is selected enable role select.
     * @param selectedOption admin dashboard or doctors dashboard.
     */
    onDashboardSelection = (selectedOption: string): void => {
        if (selectedOption === 'doctors-dashboard') {
            this.addNecessaryValidationOnDoctorDashboardSelect();
        } else if (selectedOption === 'admin-dashboard') {
            this.roleSelectionForm.get('doctorEmail')?.clearValidators();
            this.roleSelectionForm.get('doctorEmail')?.updateValueAndValidity();
            this.roleSelectionForm.get('userRole')?.enable();
        }
    };

    /**
     * add necessary validations for doctor email form.
     */
    addNecessaryValidationOnDoctorDashboardSelect = (): void => {
        this.roleSelectionForm
            ?.get('doctorEmail')
            ?.valueChanges.pipe(
                debounceTime(500),
                distinctUntilChanged(),
                takeWhile(() => this.isAlive)
            )
            .subscribe((value) => {
                if (this.roleSelectionForm?.get('doctorEmail')?.valid) {
                    const emailId: string = value;
                    if (this.isSuperAdmin) {
                        this._store.dispatch(
                            validateDoctorEmail({
                                email: emailId.toLocaleLowerCase(),
                                isDesigner: false,
                                isSuperAdmin: this.isSuperAdmin,
                                roleName: Roles.DOCTOR
                            })
                        );
                    }else{
                        this._store.dispatch(
                            validateDoctorEmail({
                                email: emailId.toLocaleLowerCase(),
                                isDesigner: true,
                                isSuperAdmin: false,
                                designerId: this.loggedInUserId,
                                roleName: Roles.DOCTOR
                            })
                        );
                    }
                }
            });
        this.roleSelectionForm.get('doctorEmail')?.setValidators([Validators.required, Validators.email]);
        this.roleSelectionForm.get('doctorEmail')?.updateValueAndValidity();
        this.roleSelectionForm.get('userRole')?.setValue(this.doctorId);
        this.roleSelectionForm.get('userRole')?.disable();
    };

    // Check if the user has super admin access.
    checkIfSuperAdmin = (query: GetRolesBasedOnUserIdQuery): boolean => {
        return query.user_role_mapping.some((userRoleMapping) => userRoleMapping.role?.name === Roles.SUPER_ADMIN);
    };

    // check if the user has designer access.
    checkIfDesigner = (query: GetRolesBasedOnUserIdQuery): boolean => {
        return query.user_role_mapping.some((userRoleMapping) => userRoleMapping.role?.name === Roles.DESIGNER);
    };

    // Check if the data is available in store for against doctor email.
    validatingDoctorEmail = (data: GetUserAndUserRoleMappingByEmailQuery | null): boolean => {
        if (data) {
            if (this.isSuperAdmin) {
                return !!data?.superAdmin?.[0]?.name;
            }else {
                return !!data?.designer?.[0]?.id;
            }
        }
        return true;
    };

    /**
     * Get doctor name for display.
     * @param data
     * @returns
     */
    getDoctorName = (data: GetUserAndUserRoleMappingByEmailQuery | null): string => {
        if (data) {
            if (this.isSuperAdmin) {
                return data?.superAdmin?.[0]?.name || '';
            }
            if (this.isDesigner) {
                return data?.designer?.[0]?.name || '';
            }
        }
        return '';
    };

    ngOnDestroy() {
        this.isAlive = false;
        if (this.availableRoleFetchSubscription$) {
            this.availableRoleFetchSubscription$.unsubscribe();
        }
    }
}
