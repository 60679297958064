import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { reducers } from './reducers';
import { UiModule } from './ui/ui.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { AuthGuard } from './core/guards/auth.guard';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from 'src/environment/environment';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { RequestInterceptor } from './core/services/request.interceptor';
import { PageNotFoundComponent } from './static-pages/container/page-not-found/page-not-found.component';

@NgModule({
    declarations: [
      AppComponent,
      PageNotFoundComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        UiModule,
        GraphQLModule,
        EffectsModule.forRoot([]),
        StoreModule.forRoot(reducers),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
        AuthGuard,
        NoAuthGuard,
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
